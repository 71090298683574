<template>
  <div>
    <RightSideBar
      v-if="isOpen"
      @submit="isOpen"
      @close="isOpen = false, $emit('close')"
      close-button
      button-class="bg-dynamicBackBtn text-white"
      width="520px"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="isOpen = false, $emit('close')" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            View Pay Grade
          </span>
        </div>
      </template>
      <template>
        <div class="w-full flex flex-col justify-start items-start gap-5">
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Location:
            </span>
            <div v-if="(Array.isArray(paygrade.locations))">
              <div class="flex flex-col justify-end text-sm capitalize font-semibold">
                <div class="flex flex-row justify-between items-center gap-2 capitalize">
                  <p class="text-sm text-darkPurple capitalize">{{ paygrade.locations[0].name }}</p>
                  <p v-if="((paygrade.locations.length - 1) > 0)" class="text-xs text-flame">
                    +{{ paygrade.locations.length - 1 }}
                  </p>
                </div>
                <template v-if="((paygrade.locations.length - 1) > 0)">
                  <div @click="isOpenLocations = true" class="flex self-end items-center">
                    <small class="text-xs text-blueCrayola font-semibold cursor-pointer">View Trail</small>
                  </div>
                </template>
              </div>
            </div>
            <div v-else>
              <span class="flex justify-end gap-2 font-semibold text-sm leading-5 text-jet">
                <span class="capitalize">{{ paygrade.locations }}</span>
              </span>
            </div>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Function:
            </span>
            <div v-if="(Array.isArray(paygrade.functions))">
              <div v-for="functions in paygrade.functions" :key="functions.id">
                <span class="flex justify-end gap-2 font-semibold text-sm leading-5 text-jet">
                  <span class="capitalize">{{ functions.name }}</span>
                </span>
              </div>
            </div>
            <div v-else>
              <span class="flex justify-end gap-2 font-semibold text-sm leading-5 text-jet">
                <span class="capitalize">{{ paygrade.functions }}</span>
              </span>
            </div>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Job Level:
            </span>
            <span v-if="paygrade.level" class="font-semibold text-sm leading-5 text-jet">
              {{ paygrade.level.name }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Designation:
            </span>
            <div v-if="(Array.isArray(paygrade.designations))">
              <div v-for="designation in paygrade.designations" :key="designation.id">
                <span class="flex justify-end gap-2 font-semibold text-sm leading-5 text-jet">
                  <span class="capitalize">{{ designation.name }}</span>
                </span>
              </div>
            </div>
            <div v-else>
              <span class="flex justify-end gap-2 font-semibold text-sm leading-5 text-jet">
                <span class="capitalize">{{ paygrade.designations }}</span>
              </span>
            </div>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Pay Grade:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ paygrade.payGradeId }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              No. of Employees:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ paygrade.totalEmployee }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Annual Range Minimum:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ convertToCurrency(paygrade.rangeMinimum) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Annual Range Midpoint:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ convertToCurrency(paygrade.rangeMidPoint) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Annual Range Maximum:
            </span>
            <span class="font-semibold text-sm leading-5 text-jet">
              {{ convertToCurrency(paygrade.rangeMaximum) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Average Compa-Ratio:
            </span>
            <span v-if="paygrade.averageCompaRatio" class="font-semibold text-sm leading-5 text-jet">
              {{ paygrade.averageCompaRatio.toFixed(2) }}%
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Average Range Penetration:
            </span>
            <span v-if="paygrade.averageRangePenetration" class="font-semibold text-sm leading-5 text-jet">
              {{ paygrade.averageRangePenetration.toFixed(2) }}
            </span>
          </div>
          <div class="w-full flex justify-between items-center">
            <span class="font-semibold text-sm leading-5 text-romanSilver">
              Last Revision:
            </span>
            <div v-if="(Date.parse(paygrade.lastReview))">
              <span class="font-semibold text-sm leading-5 text-jet">
                {{ $DATEFORMAT(new Date(paygrade.lastReview), "MMMM dd, yyyy") }}
              </span>
            </div>
            <span v-else class="font-semibold text-sm leading-5 text-jet">
              {{ paygrade.lastReview}}
            </span>
          </div>
        </div>
      </template>
    </RightSideBar>
    <template>
      <RightSideBar
        v-if="isOpenLocations"
        @close="isOpenLocations = false"
        button-class="bg-dynamicBackBtn text-white"
        :close-button="true"
      >
        <template v-slot:title>
          <div class="flex justify-start items-center">
            <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
              View All Locations
            </p>
          </div>
        </template>
        <template v-slot:default>
          <div class="flex flex-col justify-start gap-2">
            <Card v-for="location in paygrade.locations" :key="location.id" class="w-full">
              <div class="flex flex-row items-center justify-start gap-2 mx-5 my-3">
                <Icon icon-name="icon-grid" class="text-romanSilver" size="xxs" />
                <h6 class="font-semibold text-sm text-jet capitalize">{{ location.name }} </h6>
              </div>
            </Card>
          </div>
        </template>
      </RightSideBar>
    </template>
  </div>
</template>

<script>
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import RightSideBar from "@/components/RightSideBar";

  export default {
    components: {
      BackButton,
      Card,
      RightSideBar
    },
    data(){
      return {
        isOpen: false,
        isOpenLocations: false,
        paygrade: {},
      }
    },
    methods: {
      toggle(payload){
        this.paygrade = payload
        this.isOpen = true
      }
    }
  }
</script>

<style>
  .rightSidebar{
    width: 520px !important;
  }
</style>

