<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex flex-row justify-start items-center gap-4 px-2">
          <h1 class="text-xl font-extrabold">Compensation</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template v-if="hasModuleAccess">
        <template>
          <Card class="flex flex-col gap-2 px-3 py-2 mx-2 my-5">
            <h3 class="font-bold text-lg text-darkPurple">Pay Insight</h3>
            <Card class="px-5 pb-5 my-3">
              <CardFooter
                reloadcard
                search-placeholder="Paygrade"
                @reload="onGetQuery"
                @actionModal="onFilter"
                @sortType="onGetQuery({ sort: $event })"
                @searchResult="onGetQuery({ search: $event })"
              >
                <template v-slot:rightItems>
                  <div class="rounded-md shadow p-3 ml-3 cursor-pointer">
                    <Menu left @click.stop="">
                      <template v-slot:title>
                        <Icon icon-name="more_icon" size="xs" />
                      </template>
                      <template>
                        <div class="flex flex-col w-52 h-18 p-2 justify-start items-start">
                          <div class="hover:bg-ghostWhite w-full rounded-md"
                            @click="$router.push({ name: 'BulkAdjustPayGrade' })">
                            <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                              <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                              <span class="text-darkPurple text-sm leading-5 font-normal">
                                Adjust Pay Grade
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Menu>
                  </div>
                </template>
              </CardFooter>
            </Card>
            <template>
              <template v-if="isRefMarketRate === null">
                <div class="flex flex-col justify-center items-center gap-5 px-10 pb-20">
                  <Icon size="" class-name="w-72 h-72" icon-name="illustration" />
                  <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                    Compensation planning setup is not complete. Kindly go to settings to set up your market rate
                  </p>
                  <Button
                    label="Go To Compensation Settings"
                    @onClick="$router.push({ name: 'CompensationSettings' })"
                  />
                </div>
              </template>
              <template v-else>
                <template v-if="payinsights.length === 0">
                  <div class="w-full flex flex-col justify-center items-center gap-5 px-10 pb-20">
                    <Icon size="" class-name="w-80 h-80" icon-name="payinsight_Illustration" />
                    <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                      A list of pay insights for your organisaton will be shown here. Once added you can filter and perform necessary actions
                    </p>
                  </div>
                </template>
                <template v-else>
                  <Table
                    :headers="headers"
                    :items="payinsights"
                    :has-number="true"
                    :loading="isFetching"
                    :pagination-list="pagination"
                    page-sync
                    @page="onGetQuery({ page: $event })"
                    @itemsPerPage="onGetQuery({ perPage: $event })"
                  >
                    <template v-slot:item="{ item }">
                      <div v-if="item.locations" class="w-28">
                        <div v-if="(Array.isArray(item.data.locations))">
                          <div class="flex flex-col justiy-start gap-2">
                            <div class="flex flex-row justify-between items-center gap-1">
                              <p class="text-sm text-darkPurple">{{ item.data.locations[0].name }}</p>
                              <template v-if="((item.data.locations.length - 1) > 0)">
                                <p class="text-xs text-flame font-bold">+{{ item.data.locations.length - 1 }}</p>
                              </template>
                            </div>
                            <template v-if="item.data.locations.length > 1">
                              <div @click="activeLocations = item.data.locations, isOpenLocations = true">
                                <p class="text-xs text-blueCrayola font-semibold cursor-pointer">View Trail</p>
                              </div>
                            </template>
                          </div>
                        </div>
                        <span v-else class="font-normal text-sm leading-5 text-darkPurple">
                          {{ item.data.locations }}
                        </span>
                      </div>
                      <div v-if="item.functions">
                        <div v-if="(Array.isArray(item.data.functions))">
                          <div v-for="item in item.data.functions" :key="item.id" class="flex justify-start gap-2">
                            <span class="font-normal text-sm leading-5 text-darkPurple">
                              {{ item.name }}
                            </span>
                          </div>
                        </div>
                        <span v-else class="font-normal text-sm leading-5 text-darkPurple">
                          {{ item.data.functions }}
                        </span>
                      </div>
                      <div v-if="item.payGradeId" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ item.data.payGradeId }}
                        </span>
                      </div>
                      <div v-if="item.totalEmployee" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ item.data.totalEmployee }}
                        </span>
                      </div>
                      <div v-if="item.rangeMinimum" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ convertToCurrency(item.data.rangeMinimum) }}
                        </span>
                      </div>
                      <div v-if="item.rangeMidPoint" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ convertToCurrency(item.data.rangeMidPoint) }}
                        </span>
                      </div>
                      <div v-if="item.rangeMaximum" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ convertToCurrency(item.data.rangeMaximum) }}
                        </span>
                      </div>
                      <div v-if="item.id">
                        <Menu left @click.stop="">
                          <template v-slot:title>
                            <Icon icon-name="more_icon" size="xs" />
                          </template>
                          <template>
                            <div class="flex flex-col w-52 h-18 p-2 justify-start items-start">
                              <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                                @click="priviledgedActions(item.data, 'viewPayGrade')">
                                <div class="flex justify-start items-center p-2 gap-2">
                                  <Icon icon-name="icon-eye" class-name="text-carrotOrange" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                    View Pay Grade
                                  </span>
                                </div>
                              </div>
                              <div class="hover:bg-ghostWhite w-full rounded-md"
                                @click="priviledgedActions(item.data.id, 'viewEmployees')">
                                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                  <Icon icon-name="icon-eye" class-name="text-darkPurple" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                    View Employees
                                  </span>
                                </div>
                              </div>
                              <div class="hover:bg-ghostWhite w-full rounded-md"
                                @click="priviledgedActions(item.data.id, 'adjustPayGrade')">
                                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                  <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                    Adjust Pay Grade
                                  </span>
                                </div>
                              </div>
                              <div class="hover:bg-ghostWhite w-full rounded-md"
                                @click="priviledgedActions(item.data.id, 'viewRevision')">
                                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                  <Icon icon-name="icon-eye" class-name="text-mediumSeaGreen" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                    View Revision History
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </Menu>
                      </div>
                    </template>
                  </Table>
                </template>
              </template>
            </template>
          </Card>
        </template>
        <template>
          <RightSideBar
            v-if="isOpenLocations"
            @close="isOpenLocations = false"
            button-class="bg-dynamicBackBtn text-white"
            :close-button="true"
          >
            <template v-slot:title>
              <div class="flex justify-start items-center">
                <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
                  View All Locations
                </p>
              </div>
            </template>
            <template v-slot:default>
              <div class="flex flex-col justify-start gap-2">
                <Card v-for="location in activeLocations" :key="location.id" class="w-full">
                  <div class="flex flex-row items-center justify-start gap-2 mx-5 my-3">
                    <Icon icon-name="icon-grid" class="text-romanSilver" size="xxs" />
                    <h6 class="font-semibold text-sm text-jet capitalize">{{ location.name }} </h6>
                  </div>
                </Card>
              </div>
            </template>
          </RightSideBar>
        </template>
        <ViewPayGrade ref="view_paygrade" />
        <template>
          <div v-if="isOpenFilter" class="filter_table">
            <FilterTable
              v-if="isOpenFilter"
              :filter-data="filterOptions"
              @close="isOpenFilter = false"
              @submit="isOpenFilter = false"
            />
          </div>
        </template>
      </template>
      <div v-else>
        <ErrorComponent />
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
  import CardFooter from "@/components/CardFooter";
  import Icon from "@/components/Icon";
  import RightSideBar from "@/components/RightSideBar";
  import FilterTable from "@/components/FilterTable";
  import ErrorComponent from "@/modules/Admin/error403"
  import ViewPayGrade from "../Modals/ViewPayGrade";

  export default {
    name: 'CompensationPayInsights',
    components: {
      Breadcrumb,
      Table,
      Card,
      Menu,
      Icon,
      FilterTable,
      CardFooter,
      ViewPayGrade,
      RightSideBar,
      ErrorComponent,
    },
    data(){
      return {
        isLoading: true,
        isOpenLocations: false,
        isFetching: true,
        isOpenFilter: false,
        hasModuleAccess: false,
        payinsights: [],
        activeLocations: [],
        isRefMarketRate: null,
        marketRateSettings: null,
        queryParams: {},
        filterOptions: [],
        pagination: {
          page: 1,
          lastPage: 1,
          total: 1,
          from: 1,
          to: 1,
        },
        breadcrumbs: [
          {
            disabled: false,
            text: "Compensation",
            href: "CompensationOverview",
            id: "CompensationOverview"
          },
          {
            disabled: false,
            text: "Pay Insight",
            href: "CompensationPayInsight",
            id: "CompensationPayInsight"
          }
        ],
        headers: [
          { title: "location", value: "locations" },
          { title: "function", value: "functions" },
          { title: "paygrade", value: "payGradeId" },
          { title: "no of employees", value: "totalEmployee" },
          { title: "ANNUAL range minimum", value: "rangeMinimum" },
          { title: "ANNUAL range midpoint", value: "rangeMidPoint" },
          { title: "ANNUAL range maximum", value: "rangeMaximum" },
          { title: "", value: "id", image: true },
        ],
      }
    },
    computed: {
      ...mapState({
        referenceMarketRate: state => state.compensation.referenceMarketRate,
        toasted: state => state.compensation.toasted,
        // isLoading: state => state.compensation.isLoading,
        isSetMarketRate: state => state.compensation.isSetMarketRate,
      }),
    },
    methods: {
      ...mapActions({
        setMarketSettings: 'compensation/setMarketRateSettings',
        setIsLoading: 'compensation/setIsLoading'
      }),
      async onGetQuery(queryParams){
        try {
          await this.$handlePrivilege('payInsight', 'viewPayInsightRegister')
          if(queryParams !== undefined){
            this.queryParams = {...this.queryParams, ...queryParams}
            this.getOrgPayInsights(this.queryParams)
          } else {
            this.queryParams = {}
            this.getOrgPayInsights()
          }
        } catch (error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }
      },
      async priviledgedActions(payload, setAction){
        try {
          if(setAction === 'viewPayGrade'){
            await this.$handlePrivilege('payInsight', 'viewPayGradeDetails')
            this.$refs.view_paygrade.toggle(payload)
          } else if(setAction === 'viewEmployees'){
            await this.$handlePrivilege('payInsight', 'viewEmployee')
            this.$router.push({ name: 'ViewEmployeePayInsight', params: {id: payload} })
          } else if(setAction === 'adjustPayGrade'){
            await this.$handlePrivilege('payInsight', 'adjustPayGrade')
            this.$router.push({ name: 'AdjustPayGrade', params: {id: payload} })
          } else if(setAction === 'viewRevision'){
            await this.$handlePrivilege('payInsight', 'viewRevisionHistory')
            this.$router.push({ name: 'RevisionHistory', params: { id: payload} })
          }
        } catch(error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }
      },
      onFilter(){
        this.filterOptions = [
          { header: 'Location(s)', optionsBox: this.locationOptions },
          { header: 'Function(s)', optionsBox: this.functionOptions },
          { header: 'Level(s)', optionsBox: this.levelOptions },
          { header: 'Designation(s)', optionsBox: this.designationOptions },
          { header: 'Pay Grade', optionsBox: this.paygradeOptions },
          { header: 'Performance Score', optionsBox: this.rankingOptions },
          { header: 'Gender', optionsBox: ['Male', 'Female'] },
          { header: 'Manager', optionsBox: this.managerOptions },
        ]
        this.isOpenFilter = true
      },
      async getOrgPayInsights(params){
        this.isFetching = true
        await this.$_getOrgPayInsights(params).then(({ data }) => {
           this.payinsights = data.grades?.filter(({ paygrade }) => (
             paygrade?.paygrade?.referenceMarketRate === this.isRefMarketRate
           )).map(insight => ({...insight, ...insight.paygrade, ...insight.paygrade.paygrade}))
          this.pagination = data.meta
          this.isFetching = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isFetching = false
        })
      },
      async bootstrapModule(){
        await this.setMarketSettings(null)
        this.isRefMarketRate = this.referenceMarketRate
        await this.getOrgPayInsights()
        this.isLoading = false
      }
    },
    async mounted(){
      this.isLoading = true
      try {
        await this.$handlePrivilege('payInsight', 'viewPayInsightRegister')
        this.hasModuleAccess = true
        this.bootstrapModule()
      } catch (error) {
        this.hasModuleAccess = false
        this.isLoading = false
      }
    },
    watch: {
      toasted(toast){
        if(toast.type === 'success') {
          this.$toasted.success(toast.message, {duration: 3000})
        } else if(toast.type === 'error') {
          this.$toasted.error(toast.message, {duration: 3000})
        }
      }
    }
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
</style>
